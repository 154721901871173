*{
    padding: 0px;
    margin: 0px;
}
.productcard > img{
    width: 100%;
}
.productcard{
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height : 100%;
    /* border: 2px solid #fc8181; */
    /* margin: 10%; */
    box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 12px;
}

.productcard:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 12px;
}


.bottomcard{
    /* margin-top: 20px; */
    margin: auto auto 10px auto ;
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    background-color: #fc8181;
    /* bottom: 10px; */

}

.addtocartbutton{
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.addtocartwithsymbol{
    display: flex;
    flex-direction: row;
    background-color: black;
    padding: 5px;
    border-radius: 4px;
    color: white;
    min-width: 80px;
    justify-content: space-around;
    max-width: fit-content;
}

.increasedecrease{
    cursor: pointer;
}

.image-container {
    width: 200px; /* Set the desired width of the square container */
    height: 200px; /* Set the desired height of the square container */
    overflow: hidden; /* Crop the content that exceeds the container size */
  }
  
  .image-container img {
    width: 100%; /* Make the image fill the entire container */
    height: 100%; /* Make the image fill the entire container */
    object-fit: cover; /* Scale and crop the image to cover the container */
  }