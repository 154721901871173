/* .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .grid-item {
    background-color: lightgray;
    padding: 20px;
    text-align: center;
  }
  
   Media query for smaller screens 
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  } */
  
  .grid-item {
    background-color: lightgray;
    /* padding: 20px; */
    text-align: center;
  }

  .grid-container-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 20px;
  }
  .grid-container-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px;
  }

  .grid-container-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px;
  }

  .container{
    align-items: center;
  }