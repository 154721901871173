/* Base styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.card-container {
    display: grid;
    gap: 16px;
    padding: 16px;
}

/* Responsive grid layout */
@media (min-width: 1024px) {
    .card-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .card-container {
        grid-template-columns: 1fr;
    }
}

.card {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}


.aboutus-Card {
    /* background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    display: flex; /* Use Flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if desired */
    text-align: center;
}