.nav-container{
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   margin: auto;
   height: 10vh;
   box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
   /* background-color: #8ec4e8; */
   width: 100%;
   max-width: 1000px;

   
}

.nav-content{
   display: flex;
   flex-direction: row;
   width: 70%;
   justify-content: space-around;

   /* color: #262626;
    text-decoration: none;
    text-transform: uppercase; 
    line-height: 80px;
    padding: 5px 20px;
    transition: .5s;   */
   
}

.nav-logo img{
  max-width: 400px;
}
 
 .navbarLink a {
   text-decoration: none; /* Remove underlines from the links */
   /* color: white; */
   padding: 10px 20px; /* Add padding to the links for spacing */
   transition: background-color 0.3s ease; /* Smooth background color transition on hover */
   /* line-height: 15; */
   font-size: 20px;
 }
 
 /* Change link color on hover */
 .navbarLink a:hover {
   background-color: #555; /* Background color when hovering over a link */
 }