.footer {
    margin-top: 10vh;
    /* padding: 300px 0px; */
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .footer-content {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%; 
    max-width: 1200px;
  }
  
  .contact-details,
  .map-embed {
    flex: 1;
    margin: 0 10px;
  }
  
  .contact-details h4,
  .map-embed h4 {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  
    .contact-details,
    .map-embed {
      margin: 10px 0;
    }
  }
  