
.carousel-item {
    position: relative;
    text-align: center;
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.7); */
    color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
  }
  
  .text-overlay h2 {
    margin: 0;
    font-size: 24px;
  }
  